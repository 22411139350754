import { readable } from 'svelte/store';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const { location } = history;

const store = readable(location, function start(set) {
  // Listen for changes to the current location.
  history.listen((location, action) => {
    set(location);
    console.log(location, action);
    // window.Intercom('update', {
    //   locationPathName: location.pathname,
    //   locationSearch: location.search,
    // });
  });
});

export default store;
