<script>
  import { Link } from "svelte-routing";

  let interval;
  let timer = "00:00";
  function countdown() {
    clearInterval(interval);
    interval = setInterval(function() {
      timer = timer.split(":");
      let minutes = timer[0];
      let seconds = timer[1];
      seconds -= 1;
      if (minutes < 0) return;
      else if (seconds < 0 && minutes != 0) {
        minutes -= 1;
        seconds = 59;
      } else if (seconds < 10 && length.seconds != 2) seconds = "0" + seconds;

      timer = minutes + ":" + seconds;

      if (minutes == 0 && seconds == 0) clearInterval(interval);
    }, 1000);
  }

  function startInterVal() {
    timer = "00:10";
    countdown();
  }
</script>

<div class="center-container">
  <h3>Daftar Baru</h3>
  <div class="login-box">
    <div class="row">
      <form class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <input id="name" type="text" class="validate" />
            <label class="active" for="name">Nama</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="phoneNo" type="text" class="validate" />
            <label class="active" for="phoneNo">No Handphone</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="password" type="password" class="validate" />
            <label class="active" for="password">Password</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="rePassword" type="password" class="validate" />
            <label class="active" for="rePassword">Ulangi Password</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="otp" type="text" class="validate" />
            <label class="active" for="otp">Kode OTP</label>
            {#if timer !== '00:00'}
              <span class="helper-text">
                Kode OTP Dapat dikirimkan ulang dalam
                <span class="teal-text">{timer}</span>
              </span>
            {:else}
              <span class="helper-text">Tekan tombol kirim OTP.</span>
            {/if}
            <button
              type="button"
              on:click={startInterVal}
              class="btn-small button-otp"
              disabled={timer !== '00:00'}>
              Kirim OTP
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <button class="btn m-b-20">Daftar</button>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <Link to="/login">
              <a href=""  class="float-right">Kembali ke Login</a>
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<style>
  .login-box {
    min-width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
  .button-otp {
    width: auto !important;
  }
</style>