<script>
  import { Router, Link, Route } from "svelte-routing";
  import Home from "./routes/Home.svelte";
  import About from "./routes/About.svelte";

  // Auth
  import Login from "./routes/Login.svelte";
  import ForgotPassword from "./routes/ForgotPassword.svelte";
  import Register from "./routes/Register.svelte";

  // Status Code
  import Page401 from "./routes/Page401.svelte";
  import Page403 from "./routes/Page403.svelte";
  import Page404 from "./routes/Page404.svelte";
  import Page500 from "./routes/Page500.svelte";
  import Page503 from "./routes/Page503.svelte";

  let name = "world2";

  export let url = "";
</script>

<Router {url}>
  <div class="navbar-fixed">
    <nav class="navbar white">
      <div class="nav-wrapper container">
        <Link to="/" getProps={() => ({ class: 'brand-logo' })}>LOGO</Link>
        <ul class="right hide-on-med-and-down">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/login">Logout</Link>
          </li>
        </ul>
        <ul id="nav-mobile" class="sidenav">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/login">Logout</Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="root-container section no-pad-bot">
    <div class="container">
      <Route path="/about" component={About} />
      <Route path="/">
        <Home />
      </Route>
      <!-- Auth -->
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/register" component={Register} />
      <!-- Status Code -->
      <Route path="/401" component={Page401} />
      <Route path="/403" component={Page403} />
      <Route path="/404" component={Page404} />
      <Route path="/500" component={Page500} />
      <Route path="/503" component={Page503} /> 
      <Route path="" component={Page404} />
    </div>
  </div>
</Router>
